.pending,
.unfulfilled,
.partial {
    color: #f57c00;
}

.error,
.not-compliant,
.on_hold {
    color: #d32f2f;
}

.compliant,
.committed,
.force-committed,
.fulfilled {
    color: #2e7d32;
}

.multi-line-cell {
    white-space: pre-wrap;
    line-height: 1.2;
    vertical-align: top;
}