.compliance-description {
/* TODO */
color:red;
}

.compliance-description-heading {
/* TODO */
color:red;
}

.compliance-description-content {
/* TODO */
color:red;
}